import { isBrowser } from '@builder.io/sdk';
import React, { createContext, useEffect, useState } from 'react';

const DEFAULT = {
    isMobile: null,
    isDesktop: null,
    isTablet: null,
    isTopOfPage: true
};
const ResponsiveContext = createContext(DEFAULT);

export function ResponsiveContextProvider({ children }) {
    const [isMobile, setMobile] = useState(DEFAULT.isMobile);
    const [isTablet, setTablet] = useState(DEFAULT.isTablet);
    const [isDesktop, setDesktop] = useState(DEFAULT.isDesktop);
    const [isTopOfPage, setTopOfPage] = useState(DEFAULT.isTopOfPage);
    
    const handleResize = () => {
        setMobile(window.innerWidth < 600);
        setTablet(window.innerWidth > 600 && window.innerWidth < 960);
        setDesktop(window.innerWidth > 960);
    }

    const handleScroll = () => {
        setTopOfPage(window.scrollY === 0);
    };

    useEffect(() => {
        if (isBrowser) {
            window.addEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll);
            handleResize();
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <ResponsiveContext.Provider value={{ isMobile, isDesktop, isTablet, isTopOfPage }}>
        { children }
    </ResponsiveContext.Provider>
}

export default ResponsiveContext;