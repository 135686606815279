// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-signature-jsx": () => import("./../../../src/pages/admin/signature.jsx" /* webpackChunkName: "component---src-pages-admin-signature-jsx" */),
  "component---src-pages-pf-builder-jsx": () => import("./../../../src/pages/pf-builder.jsx" /* webpackChunkName: "component---src-pages-pf-builder-jsx" */),
  "component---src-templates-builder-page-jsx": () => import("./../../../src/templates/builder-page.jsx" /* webpackChunkName: "component---src-templates-builder-page-jsx" */),
  "component---src-templates-collection-page-jsx": () => import("./../../../src/templates/collection-page.jsx" /* webpackChunkName: "component---src-templates-collection-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/product-page.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */)
}

