import React, { createContext } from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';
import { query, queries } from '../utils/shopify-client';

const isBrowser = typeof window !== 'undefined';

const DEFAULT = {
    cart: null,
    sidebarIsVisible: false,
    snackbar: {
        isVisible: false,
        product: null
    }
};
const CommerceContext = createContext({ state: DEFAULT });

function reduce(state, action) {
    switch (action.type) {
        case 'cart.set':
            const cart = action.payload?.cart;
            if (cart?.id != null) localStorage.setItem('commerce.cartId', cart.id);
            if (cart?.lines != null) cart.lines = cart.lines.edges.map(edge => edge.node);
            return { ...state, cart };
        case 'sidebar.open':
            return { ...state, sidebarIsVisible: true };
        case 'sidebar.close':
            return { ...state, sidebarIsVisible: false };
        case 'sidebar.toggle':
            return { ...state, sidebarIsVisible: !state.sidebarIsVisible };
        case 'snackbar.open':
            const product = action.payload?.product;
            const snackbar = {
                isVisible: true,
                product
            };
            return { ...state, snackbar };
        case 'snackbar.clear':
            return { ...state, snackbar: DEFAULT.snackbar };
        default:
            return state;
    };
};

export function CommerceContextProvider({ children }) {
    const [state, dispatch] = useReducer(reduce, DEFAULT);

    const fetchCart = async () => {
        const lastCartId = localStorage.getItem('commerce.cartId');
        
        let cart = null;
        if (lastCartId != null) cart = await query(queries.cart.retrieve, { id: lastCartId }).then(data => data.cart);
        if (cart == null) cart = await query(queries.cart.create).then(data => data.cartCreate.cart)

        dispatch({ type: 'cart.set', payload: { cart } });
    }

    const addCartLine = async (merchandiseId, quantity = 1) => {
        const cartId = state.cart.id;
        const lines = [{ merchandiseId, quantity }];
        const { cart } = await query(queries.cart.addLine, { cartId, lines })
            .then(res => res.cartLinesAdd);
        dispatch({ type: 'cart.set', payload: { cart } });
        dispatch({ type: 'sidebar.open' });
    }

    const updateCartLine = async (lineId, quantity = 1) => {
        const cartId = state.cart.id;
        const lines = [{ id: lineId, quantity }];
        const { cart } = await query(queries.cart.updateLine, { cartId, lines })
            .then(res => res.cartLinesUpdate);
        dispatch({ type: 'cart.set', payload: { cart } });
    }

    const removeCartLine = async (lineId) => {
        const cartId = state.cart.id;
        const { cart } = await query(queries.cart.removeLine, { cartId, lineIds: [lineId] })
            .then(res => res.cartLinesRemove);
        dispatch({ type: 'cart.set', payload: { cart } });
    }

    useEffect(() => {
        if (isBrowser) fetchCart()
    }, []);

    return <CommerceContext.Provider value={{
        state,
        dispatch,
        fetchCart,
        addCartLine,
        updateCartLine,
        removeCartLine
    }}>{ children }</CommerceContext.Provider>
}

export default CommerceContext;