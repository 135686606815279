import * as React from "react"
import "./src/styles/reset.css"
import "./src/styles/fonts.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"
import "./src/styles/tailwind.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import App from "./src/components/app"

export const wrapRootElement = ({ element }) => (
  <App>{element}</App>
)
