import React from 'react';
import { CommerceContextProvider } from "../context/commerce-context";
import { ResponsiveContextProvider } from '../context/responsive-context';

export default function App({ children }) {
    return <CommerceContextProvider>
        <ResponsiveContextProvider>
            { children }
        </ResponsiveContextProvider>
    </CommerceContextProvider>
}