export class Client {

    constructor(endpoint = null, token = null, version = '2022-10') {
        this.endpoint = `https://${endpoint ?? process.env.GATSBY_SHOPIFY_STORE_URL}/api/${version}/graphql.json`;
        this.token = token ?? process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;
    }

    async query(query, variables = {}) {
        query = query.replace(/\s+/g, ' ').trim();
        const res = await fetch(this.endpoint, {
            method: 'POST',
            body: JSON.stringify({ query, variables }),
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            }
        });
        const data = await res.json().then(data => data.data);
        return data;
    }

}

const cartFragment = `{
    id,
    checkoutUrl,
    cost {
        subtotalAmount {
            currencyCode,
            amount
        },
        subtotalAmountEstimated,
        totalAmount {
            currencyCode,
            amount
        },
        totalAmountEstimated,
        totalTaxAmount {
            currencyCode,
            amount
        },
        totalTaxAmountEstimated,
    },
    lines(first: 20) {
        edges {
            node {
                id,
                quantity,
                merchandise {
                    ... on ProductVariant {
                        title,
                        id,
                        availableForSale,
                        quantityAvailable,
                        price {
                            amount,
                            currencyCode
                        },
                        compareAtPrice {
                            amount,
                            currencyCode
                        },
                        image {
                            url,
                            altText,
                            height,
                            width
                        },
                        product {
                            id,
                            handle,
                            title
                        }
                    }
                }
            }
        }
    }
}`

export const queries = {
    cart: {
        create: `mutation cartCreate  {
            cartCreate {
                cart ${cartFragment},
                userErrors {
                    field
                    message
                }
            }
        }`,
        retrieve: `query fetchCart($id: ID!) {
            cart(id: $id) ${cartFragment}
        }`,
        addLine: `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
                cart ${cartFragment}
                userErrors {
                    field
                    message
                }
            }
        }`,
        updateLine: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
            cartLinesUpdate(cartId: $cartId, lines: $lines) {
              cart ${cartFragment}
              userErrors {
                field
                message
              }
            }
        }`,
        removeLine: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
            cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
              cart ${cartFragment}
              userErrors {
                field
                message
              }
            }
        }`
    }
};
export const client = new Client();
export const query = async (...args) => client.query(...args);
export default query;